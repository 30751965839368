
import { Component, Vue } from 'vue-property-decorator'
import { phone, pInteger, vaildUrl } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { Info } from '@/types/customer'

@Component({
  name: 'CutomerAdd'
})
export default class extends Vue {
  private title = '新增私有化配置'
  private info: any = {}
  private table: any = {}

  private rules = {
    serviceName: [{ required: true, message: '请输入客户名称', trigger: ['blur', 'change'] }],
    serviceNum: [{ required: true, message: '请输入用户授权数量', trigger: ['change'] }, { validator: pInteger, trigger: ['change'] }],
    serviceAppKey: [{ required: true, message: '请输入AppKey', trigger: ['change'] }],
    serviceSecret: [{ required: true, message: '请输入密钥Secret', trigger: ['change'] }],
    serviceUrl: [{ required: true, message: '请输入服务器地址（Ip或域名）', trigger: ['change'] }],
    serviceUrlIpark: [{ validator: vaildUrl, trigger: ['blur', 'change'] }],
    serviceUrlCrm: [{ validator: vaildUrl, trigger: ['blur', 'change'] }],
    serviceUrlIot: [{ validator: vaildUrl, trigger: ['blur', 'change'] }],
    contactList: [{ required: true, message: '请添加联系人', trigger: ['change'] }],
    serviceContactName: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
    serviceContactMobile: [{ required: true, message: '请输入联系号码', trigger: ['blur', 'change'] }, { validator: phone, trigger: ['blur', 'change'] }]
  }

  private submitShow = false
  pickerOptions = {
    disabledDate (time: any) {
      return time.getTime() < Date.now()
    }
  }

  get id () {
    return this.$route.params.id
  }

  created () {
    if (this.id) {
      this.getData()
      this.title = '编辑私有化配置'
    } else {
      this.table.contactList = [{
        serviceContactName: '',
        serviceContactMobile: ''
      }]
    }
  }

  // 查询详情
  getData () {
    this.$axios.get<Info>(this.$apis.privatization.selectPrivatizationByServiceId, { serviceId: this.id }).then((res) => {
      this.info = res
      this.info.serviceNumUse = this.info.serviceNumUse || 0
      this.table.contactList = [{
        serviceContactName: this.info.serviceContactName,
        serviceContactMobile: this.info.serviceContactMobile
      }]
    })
  }

  // 更新
  updateUseNum () {
    this.$axios.post(this.$apis.privatization.updatePrivatizationNum, { serviceId: this.id }).then(() => {
      this.getData()
      this.$message({
        message: '已使用数量更新成功!',
        type: 'success'
      })
    })
  }

  // 保存
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      (this.$refs.tableInfo as ElForm).validate((valid1) => {
        if (valid && valid1) {
          if (this.id && this.info.serviceNum < this.info.serviceNumUse) {
            this.$message({
              message: '用户授权数量不能少于已使用的数量!',
              type: 'warning'
            })
            return false
          }
          this.submitShow = true
          const { serviceContactName, serviceContactMobile } = this.table.contactList[0]
          this.info.serviceContactName = serviceContactName
          this.info.serviceContactMobile = serviceContactMobile
          const url = this.id ? this.$apis.privatization.updatePrivatization : this.$apis.privatization.insertPrivatization
          this.$axios.post(url, this.info).then(() => {
            this.submitShow = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'privatization' })
          }).catch(() => {
            this.submitShow = false
          })
        }
      })
    })
  }
}
